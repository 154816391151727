import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";
import Ratings from "./Ratings";

const ProductCard = ({ data }) => {
  return (
    <>
      <div className="w-full bg-white rounded-lg shadow-xl p-5 relative cursor-pointer">
        <Link
          to={`${`/product/${data._id}`
          }`}
        >
          <img
            src={`${data.images && data.images[0]?.url}`}
            alt=""
            className="w-full h-[170px] object-contain pb-5"
          />
        </Link>
        <Link
          to={`${`/product/${data._id}`
          }`}
        >
          <h4 className="pb-3 text-[16px] font-[600]">
            {data.name.length > 40 ? data.name.slice(0, 80) + "..." : data.name}
          </h4>

          <div className="flex">
            <Ratings rating={data?.ratings} />
          </div>

          <div className="py-2 flex items-center justify-between ">
            <div className="flex">
              <h5 className={`${styles.productDiscountPrice}`}>
              AED {" "}{data.originalPrice === 0
                  ? data.originalPrice.toFixed(2)
                  : data.discountPrice.toFixed(2)}
              </h5>
              <h4 className={`${styles.price}`}>
                AED {" "}{data.originalPrice.toFixed(2) ? data.originalPrice.toFixed(2) : null}
              </h4>
            </div>
          </div>
          <p className="font-semibold text-[16px] font-[700] text-fuchsia-800 mt-4">
            In stock: {data.stock}
            </p>
          {/* Display selected tags (quality) */}
          <div className="py-2 flex">
            <p className="font-[500]">Quality:&nbsp;</p>
              {data.tags}
          </div>
        </Link>

      </div>
    </>
  );
};

export default ProductCard;
