
// categories data
export const categoriesData = [
  {
    id: 1,
    title: "Laptops",
    subTitle: "",
    subcategories: [
      {
        id: 101,
        title: "Apple",
      },
      {
        id: 102,
        title: "Dell",
      },
      {
        id: 103,
        title: "HP",
      },
      {
        id: 104,
        title: "Lenovo",
      },
      {
        id: 105,
        title: "Acer",
      },
      {
        id: 106,
        title: "Asus",
      }
    ],
  },

  {
    id: 2,
    title: "Computers",
    subTitle: "",
    subcategories: [
      {
        id: 201,
        title: "All in One",
      },
      {
        id: 202,
        title: "CPU with Monitor",
      }
    ],
  },

  {
    id: 3,
    title: "Mobiles",
    subTitle: "",
    subcategories: [
      {
        id: 301,
        title: "Apple",
      },
      {
        id: 302,
        title: "Samsung",
      },
      {
        id: 303,
        title: "Google",
      },
      {
        id: 304,
        title: "Huawai",
      },
      {
        id: 305,
        title: "Xiaomi",
      },
      {
        id: 306,
        title: "OnePlus",
      },
      {
        id: 307,
        title: "Oppo",
      },
      {
        id: 308,
        title: "Realme",
      },
      {
        id: 309,
        title: "Honor",
      },
      {
        id: 310,
        title: "Vivo",
      }
    ],
  },
  {
    id: 4,
    title: "Accessories",
    subTitle: "",
    subcategories: [
      {
        id: 401,
        title: "Mobile's Accessories",
        },
      {
        id: 402,
        title: "Laptop's Accessories",
        },
      {
        id: 403,
        title: "Computer's Accessories",
      }
    ],
  },
  {
    id: 5,
    title: "Appliances",
    subcategories: [
      {
        id: 501,
        title: "Vacuum Cleaner",
      },
      {
        id: 502,
        title: "Pressure Washer",
      },
      // Add more subcategories as needed
    ],
  },
];
