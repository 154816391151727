import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getAllProductsShop, updateProduct } from "../../redux/actions/product";

const CreateEvent = () => {
  const { seller } = useSelector((state) => state.seller);
  const { allProducts } = useSelector((state) => state.products);
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState("");

  // Fetch products only when seller._id is available
  useEffect(() => {
    if (seller && seller._id) {
      dispatch(getAllProductsShop(seller._id));
    }
  }, [dispatch, seller]);

  const handleStartDateChange = (e) => {
    const start = new Date(e.target.value);
    const minEnd = new Date(start.getTime() + 3 * 24 * 60 * 60 * 1000);
    setStartDate(start);
    setEndDate(null);
    document.getElementById("end-date").min = minEnd.toISOString().slice(0, 10);
  };

  const handleEndDateChange = (e) => {
    const end = new Date(e.target.value);
    setEndDate(end);
  };

  const today = new Date().toISOString().slice(0, 10);
  const minEndDate = startDate
    ? new Date(startDate.getTime() + 3 * 24 * 60 * 60 * 1000)
        .toISOString()
        .slice(0, 10)
    : "";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const formData = new FormData();
      formData.append("startDate", startDate);
      formData.append("endDate", endDate);

      await dispatch(updateProduct(selectedProduct, formData));
      toast.success("Product updated successfully!");
      navigate("/dashboard-events");
      window.location.reload();
    } catch (error) {
      toast.error("Failed to update product. Please try again.");
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div className="w-[90%] 800px:w-[50%] bg-white shadow h-[80vh] rounded-[4px] p-3 overflow-y-scroll">
      <h5 className="text-[30px] font-Poppins text-center">Create Event</h5>
      <form onSubmit={handleSubmit}>
        <div>
          <label className="pb-2 font-bold">
            Select Product <span className="text-red-500">*</span>
          </label>
          <select
            className="w-full mt-2 border h-[35px] rounded-[5px]"
            value={selectedProduct}
            onChange={(e) => setSelectedProduct(e.target.value)}
            required
          >
            <option value="">Choose a product</option>
            {allProducts &&
              allProducts.map((product) => (
                <option key={product._id} value={product._id}>
                  {product.name}
                </option>
              ))}
          </select>
        </div>

        <div className="pt-5">
          <label className="pb-2 font-bold">
            Event Start Date <span className="text-red-500">*</span>
          </label>
          <input
            type="date"
            value={startDate ? startDate.toISOString().slice(0, 10) : ""}
            className="mt-2 block w-full px-3 h-[35px] border rounded-[3px]"
            onChange={handleStartDateChange}
            min={today}
            required
          />
        </div>

        <div className="pt-5">
          <label className="pb-2 font-bold">
            Event End Date <span className="text-red-500">*</span>
          </label>
          <input
            type="date"
            value={endDate ? endDate.toISOString().slice(0, 10) : ""}
            className="mt-2 block w-full px-3 h-[35px] border rounded-[3px]"
            onChange={handleEndDateChange}
            min={minEndDate}
            required
          />
        </div>

        <div className="mt-4">
          <input
            type="submit"
            value={processing ? "Processing..." : "Create"}
            className={`mt-2 block w-full px-3 h-[35px] ${
              processing ? "bg-gray-300 cursor-not-allowed" : "bg-blue-500"
            } text-white rounded-[3px]`}
            disabled={processing}
          />
        </div>
      </form>
    </div>
  );
};

export default CreateEvent;
